<template>
  <a-layout id="components-layout-demo-fixed-sider">
    <!-- 侧边菜单 -->
    <a-layout-sider :style="{ overflow: 'auto', height: '100vh', position: 'fixed', left: 0 }" v-model="collapsed" :trigger="null" collapsible>
      <!-- logo -->
      <div class="logo">
        <div>
          <img class="logo_png" src="../../assets/dashboard/logo.png" alt="" />
        </div>
        <div>
          <p class="logo_font">江苏一道云</p>
        </div>
      </div>
      <!-- 菜单 -->
      <a-menu mode="inline" theme="dark">
        <!-- 菜单列表 -->
        <template v-for="(menu, index) in menus">
          <!-- 单节点菜单 -->
          <a-menu-item v-if="menu.size == 1 && !menu.baseRoute.meta.hidden" @click="toRoute(menu.baseRoute)" :key="menu.name">
            <a-icon :type="menu.icon" />
            {{ menu.baseRoute.meta.title }}
          </a-menu-item>
          <!-- 多节点菜单 -->
          <a-sub-menu v-if="menu.size > 1" :key="'sub-' + index">
            <span slot="title">
              <a-icon :type="menu.icon" /><span>{{ menu.name }}</span>
            </span>
            <template v-for="subMenu in menu.children">
              <a-menu-item @click="toRoute(subMenu)" :key="subMenu.name" v-if="!subMenu.meta.hidden">
                {{ subMenu.meta.title }}
              </a-menu-item>
            </template>
          </a-sub-menu>
        </template>
      </a-menu>
    </a-layout-sider>
    <!-- 右边内容 -->
    <a-layout id="right">
      <!-- <a-layout :style="{ marginLeft: '90px' } " v-if="collapsed==true"> -->
      <!-- 头部、顶部 -->
      <a-layout-header style="background: #fff; padding: 0">
        <!-- 隐藏/显示 菜单按钮 -->
        <a-icon class="trigger" :type="collapsed ? 'menu-unfold' : 'menu-fold'" @click="toggleCollapsed" />
        <a-button @click="logout" type="primary"> 退出登录 </a-button>
      </a-layout-header>

      <!-- 中间 -->
      <a-layout-content :style="{ margin: '24px 16px 0', overflow: 'initial' }">
        <!-- <div :style="{minHeight: '5vh'}">位置:一级页面/二级页面</div>
         <div :style="{ padding: '15px', background: '#fff', minHeight: '5vh' }"></div>
       <div :style="{ padding: '10px' }"></div> -->
        <div :style="{ minHeight: '90vh' }">
          <!-- 路由 -->
          <router-view />
        </div>
      </a-layout-content>

      <!-- 底部 -->
      <a-layout-footer :style="{ textAlign: 'center' }"> Ant Design ©2018 Created by Ant UED </a-layout-footer>
    </a-layout>
  </a-layout>
</template>

<script>
import dashboardMenu from '@/router/dashboard';
export default {
  data() {
    return {
      collapsed: false,
      menus: [],
      message: 'hello world',
    };
  },
  created() {
    //获取路由
    this.menus = dashboardMenu.group;
    // console.log(this.$route, dashboardMenu);
    if(!localStorage.getItem("adminToken")){
      console.log("aaaaaaaaaaa")
      this.$router.push({
        name: 'adminLogin',
      });
    }
  },
  methods: {
    /**登出 */
    logout() {
      this.store.admin.isLogin = false;
      localStorage.setItem('adminToken', '');
      localStorage.setItem('adminId', -1);
      //跳转至登录界面
      this.$router.push({
        name: 'adminLogin',
      });
    },
    /**路由跳转 */
    toRoute(menuInfo) {
      this.$router.push({
        name: menuInfo.name,
      });
    },
    /**隐藏侧边 */
    toggleCollapsed() {
      this.collapsed = !this.collapsed;

      if (this.collapsed) {
        let interval = setTimeout(function () {
          var right1 = document.getElementById('right'); //查找元素
          right1.style.marginLeft = '90px'; //改变样式
        }, 70);
      } else {
        var right2 = document.getElementById('right'); //查找元素
        right2.style.marginLeft = '200px'; //改变样式
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="less">
#components-layout-demo-fixed-sider .logo {
  // height: 32px;
  // background: rgba(255, 255, 255, 0.2)
  background-color: #2d6fff;
  // width: 100%;
  height: 64px;
  // margin: 16px;
  display: flex;
  // justify-content: space-around;
}
#right {
  margin-left: 200px;
}
.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}
.logo_png {
  width: 50px;
  margin-top: 20px;
  margin-left: 20px;
}
.logo_font {
  color: #ffffff;
  font-size: 17px;
  font-weight: 600;
  margin-top: 20px;
  margin-left: 20px;
}
</style>
